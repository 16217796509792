<template>
  <div class="container">
    <p v-for="item in list" :key="item"> {{ item }} </p>
    <el-input v-model="data" placeholder=""></el-input>

    <el-button type="primary" class="login-btn" @click="sendMessage()">
      发送
    </el-button>
  </div>
</template>
<script>
export default {
  name: "SignalR",
  components: {},
  data() {
    return {
      data: "",
      list: [],
    };
  },
  methods: {
    sendMessage() {
      //发送websocket消息到服务器
      this.$signalR.stockConn
        .invoke("SendMessage", this.data)
        .catch(function (err) {
          return console.error(err);
        });
    },
  },
  //在Vue的生命周期Created函数中注册相关事件
  created() {
    let that = this;
    //这里的就是在服务端指定的方法名称（客户端接收函数）
    this.$signalR.stockConn.on("ReceiveMessage", (res) => {
      //可以做相关业务逻辑
      console.log("signalr ReceiveMessage : " + res);
      that.list.push(res);
    });
  },
  mounted() {
    let baseUrl = process.env.VUE_APP_BASEURL;
    console.log(baseUrl);

    this.$signalR.stockConn.start().then(() => {
      console.log("signalr连接");
    });
  },
};
</script>